import React from "react";

import PhoneIphone from "@material-ui/icons/PhoneIphone";

import SectionServices from "./SectionServices";

import { createStyles, withStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    icon: {
      fontSize: 300
    }
  });

interface Props {
  classes: {
    icon: string;
  };
}

const SectionMobile: React.FC<Props> = ({ classes }) => (
  <SectionServices
    title="Mobile Apps"
    body="The only real alternative for products targeting a consumer or a mobile work-force. I'll be happy to look at creating and publishing an app on both the App Store and on Google Play."
    image={<PhoneIphone className={classes.icon} />}
  />
);

export default withStyles(styles)(SectionMobile);
