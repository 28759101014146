import React from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { Field, Formik, Form } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import TextField from "../components/TextField";

import { createStyles, withStyles } from "@material-ui/core/styles";
import { stringify } from "query-string";

const styles = (theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(5)
    },
    buttonForm: {
      float: "right",
      marginTop: theme.spacing(2)
    }
  });

interface Props {
  classes: {
    card: string;
    buttonForm: string;
  };
}

const SectionContact: React.FC<Props> = ({ classes }) => (
  <Card className={classes.card}>
    <Formik
      initialValues={{
        name: "",
        phone: "",
        email: "",
        message: ""
      }}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        fetch("/", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: stringify({
            "form-name": "contact",
            ...values
          })
        })
          .then(res => {
            if (res.status === 200) {
              setStatus(res.status);
            } else {
              setStatus({ error: `${res.status} - ${res.statusText}` });
            }
          })
          .catch(err => {
            setStatus({ error: err.message });
            setSubmitting(false);
          });
      }}
    >
      {({ dirty, isSubmitting, status }) => (
        <Form noValidate name="contact" data-netlify="true">
          <Field
            type="text"
            name="name"
            label="Full Name"
            component={TextField}
            disabled={isSubmitting}
          />
          <Field
            type="email"
            name="email"
            label="Email"
            component={TextField}
            disabled={isSubmitting}
          />
          <Field
            type="tel"
            name="phone"
            label="Phone"
            component={TextField}
            disabled={isSubmitting}
          />
          <Field
            name="message"
            label="Message"
            multiline
            rows="6"
            component={TextField}
            disabled={isSubmitting}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.buttonForm}
            disabled={isSubmitting || !dirty}
          >
            Send
          </Button>
          {status === 200 ? (
            <FormHelperText>
              Thank you for your message, I will contact you shortly
            </FormHelperText>
          ) : status && status.error ? (
            <FormHelperText error={true}>
              Something went wrong, try sending again or send me an email
              instead
            </FormHelperText>
          ) : null}
        </Form>
      )}
    </Formik>
  </Card>
);

export default withStyles(styles)(SectionContact);
