import React from "react";

import CloudQueueIcon from "@material-ui/icons/CloudQueue";

import SectionServices from "./SectionServices";

import { createStyles, withStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    icon: {
      fontSize: 300
    }
  });

interface Props {
  classes: {
    icon: string;
  };
}

const SectionCloud: React.FC<Props> = ({ classes }) => (
  <SectionServices
    title="Your Operations in the Cloud"
    body="Automated and documented processes are crucial to success in managing and securing your cloud infrastructure. We'll get going with my base server stack on AWS using best-practices within 30 minutes."
    image={<CloudQueueIcon className={classes.icon} />}
  />
);

export default withStyles(styles)(SectionCloud);
