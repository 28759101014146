import React from "react";
import Helmet from "react-helmet";

import { useStaticQuery, graphql } from "gatsby";

interface SEOProps {
  title?: string;
}

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
    };
  };
}

const SEO: React.FC<SEOProps> = ({ title }) => {
  const {
    site: {
      siteMetadata: { lang, description, author, ...siteMetadata }
    }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            lang
            description
            author
          }
        }
      }
    `
  );
  title = title || siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: description
        }
      ]}
    />
  );
};

export default SEO;
