import React from "react";

import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import SectionContactForm from "./SectionContactForm";

import { createStyles, withStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(4)
    },
    itemForm: {
      marginTop: theme.spacing(4)
    },
    itemText: {
      width: "100%"
    },
    root: {
      backgroundColor: "#f2f2f2"
    },
    textFooter: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    title: {
      fontWeight: 600
    }
  });

interface Props {
  classes: {
    container: string;
    itemForm: string;
    itemText: string;
    root: string;
    title: string;
    textFooter: string;
  };
}

const SectionBottom: React.FC<Props> = ({ classes }) => (
  <Grid
    container
    direction="column"
    justify="flex-start"
    alignItems="center"
    className={classes.root}
  >
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.container}
      >
        <Grid item className={classes.itemText}>
          <Typography
            id="contact"
            variant="h3"
            gutterBottom
            align="center"
            className={classes.title}
          >
            Contact
          </Typography>
          <Typography variant="body1" align="center">
            Give me a phone call on:{" "}
            <Link href="tel:+61412400243" color="primary">
              +61 412 400 243
            </Link>
          </Typography>
          <Typography variant="body1" align="center">
            Send an email to:{" "}
            <Link href="mailto:mikael@doverhag.dev" color="primary">
              mikael@doverhag.dev
            </Link>
          </Typography>
        </Grid>
        <Grid item className={classes.itemForm}>
          <SectionContactForm />
        </Grid>
      </Grid>
    </Container>
    <Container maxWidth="lg">
      <Divider />
      <Typography
        variant="body1"
        align="center"
        color="textSecondary"
        className={classes.textFooter}
      >
        Copyright © 2019 Mikael Doverhag
      </Typography>
    </Container>
  </Grid>
);

export default withStyles(styles)(SectionBottom);
