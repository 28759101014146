import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import GitHubIcon from "./GitHubIcon";
import LinkedInIcon from "./LinkedInIcon";
import SEO from "./SEO";

import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { createStyles, withStyles } from "@material-ui/core/styles";

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: "#56c4ea"
      },
      secondary: {
        main: "#232F39"
      },
      background: {
        paper: "#fafafa",
        default: "#ffffff"
      }
    },
    typography: {
      fontFamily: [
        "Lucida Grande",
        "Lucida Sans Unicode",
        "Lucida Sans",
        "Geneva",
        "Arial",
        "sans-serif"
      ].join(",")
    }
  })
);

const styles = () =>
  createStyles({
    flex: {
      flex: 1
    }
  });

interface Props {
  classes: {
    flex: string;
  };
}

const Layout: React.FC<Props> = ({ classes, children }) => (
  <MuiThemeProvider theme={theme}>
    <SEO />
    <CssBaseline />
    <AppBar position="static" color="secondary">
      <Toolbar disableGutters>
        <Container maxWidth="lg">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" color="inherit" className={classes.flex}>
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                href="https://www.linkedin.com/in/mikael-doverhag-80b51b19"
                color="inherit"
                aria-label="LinkedIn"
              >
                <LinkedInIcon />
              </IconButton>
              <IconButton
                href="https://github.com/mdoverhag"
                color="inherit"
                aria-label="GitHub"
              >
                <GitHubIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
    {children}
  </MuiThemeProvider>
);

export default withStyles(styles)(Layout);
