import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import { createStyles, withStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(4)
    },
    title: {
      fontWeight: 600
    }
  });

interface Props {
  title: string;
  body: string;
  image: JSX.Element;
  classes: {
    container: string;
    title: string;
  };
}

const SectionServices: React.FC<Props> = ({ title, body, image, classes }) => (
  <Grid
    container
    direction="column"
    justify="flex-start"
    alignItems="center"
    className={classes.container}
  >
    <Grid item>
      <Typography variant="h3" gutterBottom className={classes.title}>
        {title}
      </Typography>
    </Grid>
    <Grid item></Grid>
    <Container maxWidth="sm">
      <Typography variant="body1" gutterBottom align="center">
        {body}
      </Typography>
    </Container>
    <Grid item>{image}</Grid>
  </Grid>
);

export default withStyles(styles)(SectionServices);
