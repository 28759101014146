import React from "react";

import SyncIcon from "@material-ui/icons/Sync";

import SectionServices from "./SectionServices";

import { createStyles, withStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    icon: {
      fontSize: 300
    }
  });

interface Props {
  classes: {
    icon: string;
  };
}

const SectionIntegrations: React.FC<Props> = ({ classes }) => (
  <SectionServices
    title="Data Integrations"
    body="Make sure all the third-party services that you love have the latest version of your data."
    image={<SyncIcon className={classes.icon} />}
  />
);

export default withStyles(styles)(SectionIntegrations);
