import React from "react";

import LaptopMacIcon from "@material-ui/icons/LaptopMac";

import SectionServices from "./SectionServices";

import { createStyles, withStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    icon: {
      fontSize: 300
    }
  });

interface Props {
  classes: {
    icon: string;
  };
}

const SectionWebApps: React.FC<Props> = ({ classes }) => (
  <SectionServices
    title="Web Applications"
    body="There's nothing wrong with Excel for prototyping or trying different models with your data, but it can be quite limiting long-term. Driving automation of your most common manual tasks with a web portal could help improve your operational efficiency, the quality of your data or let customer manage their own accounts."
    image={<LaptopMacIcon className={classes.icon} />}
  />
);

export default withStyles(styles)(SectionWebApps);
