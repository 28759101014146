import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import Layout from "../components/Layout";
import SectionCloud from "../components/SectionCloud";
import SectionBottom from "../components/SectionBottom";
import SectionIntro from "../components/SectionIntro";
import SectionIntegrations from "../components/SectionIntegrations";
import SectionMobile from "../components/SectionMobile";
import SectionWebApps from "../components/SectionWebApps";

import { createStyles, withStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    containerIntro: theme.mixins.gutters({
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10)
    }),
    layoutServices: theme.mixins.gutters({
      marginBottom: theme.spacing(2)
    })
  });

interface Props {
  classes: {
    containerIntro: string;
    layoutServices: string;
  };
}

const IndexPage: React.FC<Props> = ({ classes }) => (
  <Layout>
    <Container maxWidth="lg" className={classes.containerIntro}>
      <SectionIntro />
    </Container>
    <Container maxWidth="xl" className={classes.layoutServices}>
      <Grid container spacing={2} wrap="wrap">
        <Grid item sm={12} md={12}>
          <SectionWebApps />
        </Grid>
        <Grid item sm={12} md={6}>
          <SectionMobile />
        </Grid>
        <Grid item sm={12} md={6}>
          <SectionIntegrations />
        </Grid>
        <Grid item sm={12} md={12}>
          <SectionCloud />
        </Grid>
      </Grid>
    </Container>
    <SectionBottom />
  </Layout>
);

export default withStyles(styles)(IndexPage);
