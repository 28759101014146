import React from "react";

import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import Image from "../components/image";

import { createStyles, withStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600
    },
    profileImage: {
      float: "right",
      width: 120,
      height: 120,
      marginBottom: theme.spacing(1)
    },
    bodyText: {
      marginTop: theme.spacing(2)
    },
    button: {
      marginTop: theme.spacing(6)
    }
  });

interface Props {
  classes: {
    button: string;
    title: string;
    profileImage: string;
    bodyText: string;
  };
}

const SectionIntro: React.FC<Props> = ({ classes }) => (
  <div>
    <div className={classes.profileImage}>
      <Image />
    </div>
    <div>
      <Typography variant="h3" className={classes.title}>
        Mikael Doverhag
      </Typography>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Software Developer
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        I work as a software developer, based out of{" "}
        <Link href="https://goo.gl/maps/XUaRBnQtgoiz8DYn7" color="primary">
          Bundaberg, Australia
        </Link>
        . For most of my career I've been writing crew planning software for
        commercial aviation enterprises. However, I always enjoyed how
        accessible web-based applications could be if done right, so in recent
        years I made a switch to work mostly on web portals and integrating them
        with other web services for my own clients on a freelance basis.
      </Typography>
      <Typography variant="body1" className={classes.bodyText}>
        Currently I&apos;m thinking a lot about how Small to Medium Enterprise
        Businesses can best leverage web, mobile and the cloud to improve their
        operations. If you&apos;re a software consultant, business owner or
        otherwise just have an interest in the topic, send me a message.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        href="#contact"
        className={classes.button}
      >
        Get in touch
      </Button>
    </div>
  </div>
);

export default withStyles(styles)(SectionIntro);
